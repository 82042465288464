import React from 'react';
import Home from './components/Home';
import Education from './components/Education';
import Skills from './components/Skills';
import Projects from './components/Projects';
import MatrixRain from './components/MatrixRain';
import './index.css';

const App = () => (
  <div className="App">
    <MatrixRain />
    <Home />
    <Education />
    <Skills />
    <Projects />
  </div>
);

export default App;
