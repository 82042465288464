import React from 'react';

const Skills = () => (
  <section id="skills" className="section">
    <h2>Skills</h2>
    <ul className="skills-list">
      <li>Python</li>
      <li>JavaScript</li>
      <li>C++</li>
      <li>Java</li>
      <li>React</li>
      <li>Node.js</li>
      <li>Machine Learning</li>
      <li>Data Analysis</li>
      <li>SQL</li>
      <li>Git</li>
      <li>HTML & CSS</li>
    </ul>
  </section>
);

export default Skills;
