import React from 'react';

const Projects = () => (
  <section id="projects" className="section">
    <h2>Projects</h2>
    <div className="project-item">
      <h3>Package Delivery Algorithm</h3>
      <p>Developed an algorithm to optimize delivery routes for packages using the nearest neighbor algorithm in Python.</p>
      <a href="https://github.com/mogenthe1/package-delivery-algorithm" target="_blank" rel="noopener noreferrer" className="github-button">View on GitHub</a>
    </div>
    <div className="project-item">
      <h3>Email Spam Detection</h3>
      <p>Built a machine learning model in Python to classify emails as spam or non-spam.</p>
      <a href="https://github.com/mogenthe1/email-spam-detection-system" target="_blank" rel="noopener noreferrer" className="github-button">View on GitHub</a>
    </div>
    <div className="project-item">
      <h3>Minecraft Workshop Practice Minigame</h3>
      <p>Developed a Minecraft minigame inspired by Hypixel’s “Workshop” from Party Games, using Java and the Bukkit API.</p>
      <a href="https://github.com/mogenthe1/WorkbenchPractice" target="_blank" rel="noopener noreferrer" className="github-button">View on GitHub</a>
    </div>
  </section>
);

export default Projects;
// test 