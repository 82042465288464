import React from 'react';
import resume from '../MC_resume.pdf'; // Adjust the path if necessary

const Home = () => (
  <section id="home" className="section home">
    <div className="content">
      <h1>Morgen Carter</h1>
      <h2 className="subtitle">Software Engineer</h2>
      <div className="contact-links">
        <a href="mailto:morgencarter69@gmail.com">Email</a>
        <a href="https://www.linkedin.com/in/morgen-c-205532259/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://github.com/mogenthe1" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href={resume} target="_blank" rel="noopener noreferrer">Resume</a>
      </div>
      <p className="intro">
        I am a software engineer who lives and breathes computer codes. 
        When I am not thinking about computer codes, you might find me simply reading a book quietly on the edge of my bed, or 
        taking a walk in the middle of nowhere.
      </p>
    </div>
  </section>
);

export default Home;
